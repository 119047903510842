/* CustomModal.css */

.right-modal {
  display: flex !important;
  align-items: stretch;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  width: 70%;
  max-width: 70%;
  height: 100%;
  margin: 0;
  padding: 0;
  /* background-color: #fff; */
  overflow-y: auto;
  outline: 0;
  transition: opacity 0.15s linear;
  box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 1px,
    rgba(9, 30, 66, 0.31) 0px 0px 20px -6px;
}

.right-modal .modal-dialog {
  margin: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  vertical-align: middle;
}
.custom-radio-button:checked {
  background-color: #2f5173;
  border-color: #2f5173;
  opacity: 1;
}
.right-modal .modal-content {
  border: none;
  border-radius: 5px 0 0 5px;
  height: 100%;
}

.right-modal .modal-body {
  overflow-y: auto;
  height: 100vh;
  /* Enable vertical scrolling */
}

.right-modal .modal-backdrop {
  background-color: none;
}

/* Media query for smaller screens */
@media (min-width: 320px) and (max-width: 786px) {
  .right-modal {
    width: 100%;
    max-width: 100%;
  }

  .right-modal .modal-dialog {
    max-width: 100%;
  }

  .right-modal .modal-content {
    border: none;
    border-radius: 0px;
    height: 100vh;
  }
}
